import React, { useState, useEffect, useReducer } from "react"
import { Link } from "gatsby"
import Navigation from "../Navigation"
import { Logo } from "./Branding"
import { motion } from "framer-motion"

const Header = ({ location }) => {
  const isHome = location.pathname === "/" ? true : false
  const [logoIsVisible, setLogoIsVisible] = useState(false)
  const [active, toggleActive] = useReducer(state => !state, false)

  useEffect(() => {
    function checkPosition() {
      let windowY = window.scrollY
      if (windowY > 150) {
        setLogoIsVisible(true)
      } else {
        setLogoIsVisible(false)
      }
    }
    window.addEventListener("scroll", checkPosition)
  })
  const shouldShowLogo = logoIsVisible || !isHome || active
  return (
    <div className="fixed top-0 left-0 w-full z-50 transition duration-50 backdrop-blur-lg bg-white/60">
      <div className="max-w-screen-2xl mx-auto border-b border-[rgba(0,0,0,0.08)] px-5 py-4 md:px-10 overflow-hidden">
        <Link
          to="/"
          onClick={() => active && toggleActive()}
          className="absolute flex items-end space-x-3 mr-10 mt-[2px]"
          style={{
            pointerEvents: shouldShowLogo ? "auto" : "none",
          }}
        >
          <Logo visible={shouldShowLogo} small />
          <motion.h2
            className={`text-lg font-semibold text-gray-700`}
            initial={false}
            animate={{ y: shouldShowLogo ? 0 : -100 }}
            transition={{ ease: "easeInOut" }}
          >
            Atril flamenco
          </motion.h2>
        </Link>
        <Navigation
          isLogoVisible={shouldShowLogo}
          active={active}
          toggle={toggleActive}
          location={location}
        />
      </div>
    </div>
  )
}

export default Header
