import React from "react"
import { navigate } from "gatsby"
import { ArrowRight } from "react-feather"
import { Button, ChevronRightIcon } from "@atrilflamenco/react-components"
import { motion } from "framer-motion"
import Hamburger from "./lib/Hamburger"

const links = [
  {
    name: "Recursos",
    slug: "/recursos",
  },
  {
    name: "Planes de lectura",
    slug: "/planes-de-lectura",
  },
  {
    name: "Suscripción",
    slug: "/suscripcion",
  },
  {
    name: "Autores",
    slug: "/autores",
  },
]

export default function Navigation({
  isLogoVisible,
  active,
  toggle,
  location,
}) {
  var user = null
  var name = "atrilUser"
  var nameEQ = name + "="

  if (typeof document !== `undefined`) {
    var ca = document.cookie.split(";")
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) === " ") c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) user = c.substring(nameEQ.length, c.length)
    }
  }

  const Links = ({ callback, inline }) => (
    <motion.ul
      className={`flex ${inline ? "" : "flex-col"}`}
      variants={{
        open: {
          transition: {
            type: "spring",
            bounce: 0,
            duration: 0.7,
            delayChildren: 0.3,
            staggerChildren: 0.05,
          },
        },
        closed: {
          transition: {
            type: "spring",
            bounce: 0,
            duration: 0.3,
          },
        },
      }}
    >
      {links.map(link => (
        <motion.li
          variants={{
            open: {
              opacity: 1,
              y: 0,
              transition: { type: "spring", stiffness: 300, damping: 24 },
            },
            closed: { opacity: 0, y: -20, transition: { duration: 0.2 } },
          }}
        >
          <Button
            key={link.slug}
            variant="link"
            onClick={() => {
              navigate(link.slug)
              callback && callback()
            }}
            active={location.pathname === link.slug}
            className="font-normal text-sm"
          >
            {link.name}
          </Button>
        </motion.li>
      ))}
    </motion.ul>
  )

  const Actions = () =>
    !user ? (
      <>
        <Button
          variant="ghost"
          href="http://atril.clicksound.io:3000/login"
          className="justify-center font-normal text-sm"
        >
          Iniciar sesión
        </Button>
        <Button
          variant="contained"
          icon={<ArrowRight color="currentColor" strokeWidth={2} size={16} />}
          iconPosition="end"
          href="http://atril.clicksound.io:3000/signup"
          className="justify-center"
        >
          Regístrate
        </Button>
      </>
    ) : (
      <Button
        icon={<ChevronRightIcon />}
        iconPosition="end"
        href="http://atril.clicksound.io:3000/"
      >
        Ir a la App
      </Button>
    )

  return (
    <div className="flex flex-wrap items-center w-full justify-end lg:justify-between">
      <motion.div
        initial={false}
        animate={{ x: isLogoVisible ? 200 : 0 }}
        className="hidden lg:inline-block"
      >
        <Links inline />
      </motion.div>
      <Hamburger
        className="inline-block lg:hidden"
        active={active}
        toggle={toggle}
      />
      <div className="space-x-2 !hidden lg:!flex">
        <Actions />
      </div>

      <motion.div
        initial={false}
        animate={active ? "open" : "closed"}
        variants={{
          open: {
            height: "calc(100vh - 4rem)",
            pointerEvents: "all",
          },
          closed: {
            height: 0,
            pointerEvents: "none",
          },
        }}
        className="w-full relative"
      >
        <motion.div className="-mx-2 py-5 flex flex-col h-full justify-between">
          <Links className="font-bold" callback={toggle} />
          <motion.div
            className="flex flex-col space-y-3"
            animate={active ? "open" : "closed"}
            variants={{
              open: {
                opacity: 1,
                y: 0,
                transition: {
                  type: "spring",
                  stiffness: 200,
                  damping: 24,
                  delay: 0.4,
                },
              },
              closed: {
                opacity: 0,
                y: 100,
              },
            }}
          >
            <Actions />
          </motion.div>
        </motion.div>
      </motion.div>
    </div>
  )
}
