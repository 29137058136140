import React from "react"
import Layout from "./src/components/Layout"
import { AnimatePresence } from "framer-motion"

const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>
    <AnimatePresence>
      <div key={props.location.key} className="absolute w-full">
        {element}
      </div>
    </AnimatePresence>
  </Layout>
)

export default wrapPageElement
