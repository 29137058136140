exports.components = {
  "component---src-pages-autores-jsx": () => import("./../../../src/pages/autores.jsx" /* webpackChunkName: "component---src-pages-autores-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-planes-de-lectura-jsx": () => import("./../../../src/pages/planes-de-lectura.jsx" /* webpackChunkName: "component---src-pages-planes-de-lectura-jsx" */),
  "component---src-pages-recursos-jsx": () => import("./../../../src/pages/recursos.jsx" /* webpackChunkName: "component---src-pages-recursos-jsx" */),
  "component---src-pages-suscripcion-jsx": () => import("./../../../src/pages/suscripcion.jsx" /* webpackChunkName: "component---src-pages-suscripcion-jsx" */),
  "component---src-templates-author-jsx": () => import("./../../../src/templates/author.jsx" /* webpackChunkName: "component---src-templates-author-jsx" */),
  "component---src-templates-book-jsx": () => import("./../../../src/templates/book.jsx" /* webpackChunkName: "component---src-templates-book-jsx" */),
  "component---src-templates-plan-jsx": () => import("./../../../src/templates/plan.jsx" /* webpackChunkName: "component---src-templates-plan-jsx" */)
}

