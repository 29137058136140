import React from "react"
import { motion } from "framer-motion"
import { Menu, X } from "react-feather"

export default function Hamburger({ className, active, toggle }) {
  return (
    <motion.button
      onClick={toggle}
      whileTap={{ scale: 0.8 }}
      className={`p-1 flex items-start justify-center aspect-square ${className}`}
    >
      <motion.span animate={{ rotate: active ? 180 : 0 }}>
        {active ? <X strokeWidth={1} /> : <Menu strokeWidth={1} />}
      </motion.span>
    </motion.button>
  )
}
