import React from "react"
import Header from "./lib/Header"
import { lightThemeClass } from "@atrilflamenco/react-components"

import "@atrilflamenco/react-components/dist/style.css"

const Layout = ({ children, location }) => (
  <div className={lightThemeClass}>
    <Header location={location} />
    {children}
  </div>
)

export default Layout
