import React from "react"
import { motion } from "framer-motion"
import { AtrilflamencoIcon } from "@atrilflamenco/react-components"

export const Logo = ({ visible }) => (
  <motion.div animate={{ y: visible ? 0 : -100 }} initial={false}>
    <AtrilflamencoIcon />
  </motion.div>
)

const Branding = () => {
  return (
    <div className="space-y-4">
      <AtrilflamencoIcon size="lg" />
      <h2 className="text-2xl font-semibold text-gray-600">Atril flamenco</h2>
    </div>
  )
}

export default Branding
